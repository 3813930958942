import React from 'react'

import {
    Card,
    Typography,
    Box,
    Chip,
    Avatar,
    Tooltip
} from '@mui/material'

import { green, grey } from '@mui/material/colors';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function SingleSubtaskResultsCard(props){
    return(
        <Card sx={{p: 1, textAlign: "left", mb: 1}}>
            <Box sx={{display: "flex", flexDirection: "row", pb: 1}}>
                {
                    props.task.completed_by_you ? 
                    <Tooltip title="You have completed this task"><CheckCircleIcon sx={{color: green[400], mr: 1}}></CheckCircleIcon></Tooltip> : 
                    <Tooltip title="You haven't completed this task yet"><CheckCircleIcon sx={{color: grey[300], mr: 1}} /></Tooltip>
                }
                <Typography sx={{pb: .5}}>{props.task.task_name}</Typography>
            </Box>
            <Box sx={{display: "flex", flexDirection: "both", alignItems: "left", flexWrap: "wrap"}}>
                {props.task.completed_by?.map((username,i) => <Chip key={username} sx={{mr: .5, mb: .5}} variant="small" label={username} />)}
            </Box>
        </Card>
    )
};

export default SingleSubtaskResultsCard;
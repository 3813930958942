import React from "react";
import {
    Card, Typography, Container, Button, Grid, Chip, CardActions, CardHeader,
    CardContent
} from '@mui/material'
import { grey } from '@mui/material/colors';

import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import AddIcon from '@mui/icons-material/Add';

function SingleComputerCard(props){
    return(
            <Grid item xs={8} sm={6}  alignItems="center" justifyContent="center">
                <Card sx={{p: 1}}>
                <CardHeader title={<>{props.friendly_name} {(props.container?.running === true ? (<Chip label="running" color="success" size="small" sx={{float: "right"}}/>) : (<></>))}</>}/>
                <CardContent dangerouslySetInnerHTML={{ __html: props?.description }} sx={{pt:0, pb:0}}></CardContent>
                <CardActions>
                {props.container === null ? (<Button  color="success" onClick={() => props.onCreate(props.image_id)} ><AddIcon/>Create</Button>): (<></>)}
                {props.container?.running === false ? (<Button   color="success" onClick={() => props.onStart(props.container?.id)} ><PlayArrowIcon/>Start</Button>) : (<></>)}
                {props.container?.running === true ? (<Button   color="error" onClick={() => props.onStop(props.container?.id)}><StopIcon/>Stop</Button>):(<></>)}
                {props.container !== null ?(  <Button   color="error" onClick={() => props.onDelete(props.container?.id)}><DeleteIcon/>Delete</Button>):(<></>)}
                </CardActions>
            </Card>          
        </Grid>
    );
}

export default SingleComputerCard;
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import { green, grey, orange } from "@mui/material/colors";
import TaskDialog from "./TaskDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";

function TaskPreviewCard(props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      <Card variant="outlined" sx={{p: 1, mt:1}}>
      <Box sx={{display: "flex", alignItems: "center", mr: 2, cursor: "pointer"}} onClick={handleOpen}>
                {props.completed ? 
                    <Tooltip title="Task completed"><CheckCircleIcon sx={{color: green[400], mr: 2}}></CheckCircleIcon></Tooltip> : 
                    <Tooltip title="Task not completed"><CheckCircleIcon sx={{color: grey[300], mr: 2}} /></Tooltip>
                }
                <Typography>{props.name}</Typography>
        </Box>
      </Card>
      <TaskDialog task={props.task} open={open} handleClose={handleClose} />
      </>
  );
}

export default TaskPreviewCard;

import React from "react";

import {
    Card, Typography, Container, Button, Grid, Paper
} from '@mui/material'




import { useQuery } from "react-query";
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import {BACKEND_URL} from '../config'
import SingleComputerCard from "./SingleComputerCard";


function useImages() {
    return useQuery("images", async () => {
      const { data } = await axios.get(
        BACKEND_URL + '/images', { withCredentials: true }
      );
      return data;
    }, {
      refetchInterval: 1000,
      refetchIntervalInBackground: true,
    });
  }
  





function ComputersPage() {
    const queryClient = useQueryClient();

    const imagesReq = useImages();

    async function stopContainer(id){
        await axios.post(
            BACKEND_URL + `/containers/${id}/stop`, {}, {withCredentials: true},
        );
        queryClient.invalidateQueries('computerStatus')
    }
    async function startContainer(id){
        await axios.post(
            BACKEND_URL + `/containers/${id}/start`, {}, {withCredentials: true},
        );
        queryClient.invalidateQueries('computerStatus')
    }
    async function createContainer(imageId){
        await axios.post(
            BACKEND_URL + '/containers', {image_id: imageId}, {withCredentials: true}
        );
        queryClient.invalidateQueries('computerStatus')
    }
    async function deleteContainer(id){
        await axios.delete(
            BACKEND_URL + `/containers/${id}`, {withCredentials: true}
        );
        queryClient.invalidateQueries('computerStatus')
    }

    return(
        <>
        <h1>Computers</h1>
        <Paper sx={{pl:4, pr:4, pt:1, pb: 1, m:4}}>
        <p>Select which computer you want to use to when <code>ssh</code>-ing into the course. Some tasks or chapters requires using a specific computer.</p>
        <p>If you delete and re-create a computer, it will be reset to its initial state. This is useful if you accidentally messed up something, like deleting the task files.</p>
        </Paper>
        <Grid container spacing={2} justifyContent="center" sx={{maxWidth: '100vw'}}>
            {imagesReq.status === "loading" ? (
              "Loading..."
            ) : imagesReq.status === "error" ? (
              <span>Error: {imagesReq.error.message}</span>
            ) : (
              <>
                {imagesReq.data.map((item,i) => <SingleComputerCard 
                    key={item.id} 
                    image_id={item.id} 
                    friendly_name={item.friendly_name}
                    description={item.description}
                    container={item.container}
                    onCreate={createContainer}
                    onStart={startContainer}
                    onStop={stopContainer}
                    onDelete={deleteContainer}
                />)}   
              </>
            )}      
        </Grid>
        </>
    );
}

export default ComputersPage;
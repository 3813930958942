import React from "react";
import { Alert, TextField, Dialog, DialogContent, DialogActions, DialogTitle, Container, Button, Tooltip, Chip, Box, Typography } from "@mui/material";

import { green, grey } from '@mui/material/colors';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useQuery } from "react-query";
import axios from 'axios';
import {BACKEND_URL} from '../config'

function useSubmitFlag(id, val){
  return useQuery("task" + id, async () => {
      const { data } = await axios.post(
        BACKEND_URL + `/tasks/${id}/submit_flag`,
        {flag: val},
        {withCredentials: true}
      );
      return data;
    },
    {
      manual: true,
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    });
}


function TaskDialog(props) {

  const [answer, setAnswer] = React.useState("");
  const submitFlagResult = useSubmitFlag(props.task.id, answer);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter'){
      submitFlagResult.refetch();
    }
}

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={props.open}
        onClose={props.handleClose}
      >
        <DialogTitle>
          <Box sx={{display: "flex", alignItems: "center", mr: 2}}>
                {props.task.completed == true ? 
                    <Tooltip title="Task completed"><CheckCircleIcon sx={{color: green[400], mr: 2}}></CheckCircleIcon></Tooltip> : 
                    <Tooltip title="Task not completed"><CheckCircleIcon sx={{color: grey[300], mr: 2}} /></Tooltip>
                }
                <Typography variant="h6">{props.task.name}</Typography>
                {(()=> {
                    switch(props.task.type) {
                        case "auto":
                            return <Tooltip title="Task is automatically verified as completed"><Chip sx={{marginLeft: 2}} label="Auto" size="small" variant="outlined" /></Tooltip>;
                        case "flag":
                            return <Tooltip title="You need to submit an answer below"><Chip sx={{marginLeft: 2}} label="Flag" size="small" variant="outlined" /></Tooltip>
                    }
                })()}
            </Box>
        </DialogTitle>
        <DialogContent>
        <Container sx={{ mt: 2, mb: 4 }}  dangerouslySetInnerHTML={{ __html: props.task.description }} />
          {!props.task.completed && props.task.type == "flag" &&
                <div>
                {submitFlagResult.status === "loading" ? (
                  "Verifying..."
                ) : submitFlagResult.status === "error" ? (
                  <Alert severity="error">Error: {submitFlagResult.error.response.data?.userMessage}</Alert>
                ) : submitFlagResult.status === "idle" ? (
                    <></>) : (
                  <>
                    <Alert severity="success">Correct!</Alert>
                  </>
                )}
                <Box variant="outlined" sx={{display: "flex", flexDirection: "row", borderRadius: 2, mt: 3, alignItems: "center", justifyContent: "left"}}>
                    <TextField sx={{}} value={answer} onChange={e => setAnswer(e.target.value)} label="Write your answer here" variant="filled" onKeyDown={handleKeyDown}/>
                    <Button sx={{ml: 1}} variant="contained" onClick={submitFlagResult.refetch}>Submit</Button>
                </Box>
                </div>
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default TaskDialog;
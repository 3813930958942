import React, { useState } from "react";
import TaskPreviewCard from "./TaskPreviewCard";
import {
  Tooltip,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { green, grey, orange } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PendingIcon from "@mui/icons-material/Pending";

function ChapterTasksCard(props) {
  return (
      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{display: "flex", alignItems: "center", mr: 2}}>
                {props.completed === true ? 
                    <Tooltip title="All tasks completed!"><CheckCircleIcon sx={{color: green[400], mr: 2}}></CheckCircleIcon></Tooltip> : 
                    <Tooltip title="Not all tasks completed"><CheckCircleIcon sx={{color: grey[300], mr: 2}} /></Tooltip>
                }
                <Typography>Chapter: {props.name}</Typography>
        </Box>
        </AccordionSummary>
        <AccordionDetails>
        {props.tasks.map((task,i) => 
            <TaskPreviewCard 
                key={task.id}
                id={task.id}
                task={task}
                name={task.name}
                type={task.type}
                description={task.description}
                completed={task.completed}
            />
        )}
        </AccordionDetails>
      </Accordion>
  );
}

export default ChapterTasksCard;

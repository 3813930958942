import React, { useState, useEffect } from 'react';

const TypingEffect = () => {
    const [brainfuck, setBrainfuck] = useState('');
    const [index, setIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [shuffledChars, setShuffledChars] = useState([]);
    const [shineIndex, setShineIndex] = useState(-1);
    const [shining, setShining] = useState(false);

    const chars = [
        'sudo rm -rf / --no-preserve-root',
        ':(){ :|:& };:',
        'find / -type f -exec chmod 644 {} \\;',
        'sudo apt-get update && sudo apt-get upgrade -y',
        'sudo find / -name "*.log" -type f -delete',
        'sudo tcpdump -i eth0 -w /tmp/capture.pcap',
        'tar -cvpzf backup.tar.gz --exclude=/backup.tar.gz --one-file-system /',
        'sudo mount -o remount,rw /',
        'echo "Ascend is cool!"',
        'git bisect reset',
        'git checkout -b final-final-fix',
        'sudo make && sudo make modules_install && sudo make install',
        'cat /dev/random > /dev/tty',
        'echo 1 > /proc/sys/kernel/sysrq',
        'sudo systemctl stop apache2',
        'sudo useradd -m -G sudo emma',
        'sudo dd if=/dev/zero of=/dev/sda bs=4k',
        'sudo nano /etc/hosts',
        'sudo fdisk -l',
        'sudo traceroute google.com',
        'git stash',
        'alias nano="vim"',
        'git config --global user.email "linus@torvalds.com',
        'man man',
        'tmux attach -t session',
        'sudo mkfs.ext4 /dev/sda1',
        'history -c',
        'sed ‘s/windows/linux/g’ best_operating_system_ever.txt',
        'neofetch',
        'uname -r',
        'git rebase -i HEAD~3',
        'sudo dmesg | less',
        'vim .vimrc',
        'sudo iptables -F',
        'sudo ip link set eth0 down',
        'sudo reboot',
        'echo "Øyvind is cool"',
        'sudo shutdown -h now',
        'sudo apt-get full-upgrade',
        'ps aux',
        'lsblk -o NAME,FSTYPE,SIZE,MOUNTPOINT,LABEL', 
        ':w !sudo tee %', 
        'sudo systemctl disable firewalld',
        'sudo ln -s /bin/false /bin/true',
        'apt-get source linux',
        'bcachefs subvolume snapshot',
        'echo "In case of fire: git commit, git push, leave building."',
        'sudo cp /etc/fstab /etc/fstab.bak',
        'sudo dnf remove kernel-devel',
        'sudo crontab -e',
        'echo 3 > /proc/sys/vm/drop_caches',
        'for r in /dev/ram*; do cat /dev/zero > $r; done',
        'sudo strace -p $(pgrep sshd)',
        'sudo sysctl -w net.ipv4.tcp_syncookies=1',
        'echo "PermitRootLogin no" >> /etc/ssh/sshd_config',
        'ssh-keygen -t rsa -b 4096',
        'sudo iptables -A INPUT -p tcp --dport 22 -j ACCEPT',
        'df -h',
        'fortune | cowsay', 
        'sudo lvextend -L +10G /dev/vg0/lv0',
        'sudo lsof -i -P -n',
        'echo "nameserver 8.8.8.8" | sudo tee /etc/resolv.conf',
        'sudo vim /etc/sudoers',
        'sudo make menuconfig',
        'sudo update-initramfs -u',
        'echo "kernel.core_pattern = /tmp/core.%e.%p" | sudo tee -a /etc/sysctl.conf && sudo sysctl -p',
        'sudo systemctl start nginx',
        'cat /proc/cpuinfo',
        'sudo grub-mkconfig -o /boot/grub/grub.cfg',
        'sudo mkdir -p /mnt/data',
        'ss -tuln',
        'sudo apt-get autoremove',
        'cat /proc/cpuinfo',
        'echo c > /proc/sysrq-trigger',
        'uptime -p',
        'sudo systemctl enable --now docker',
        'sudo unattended-upgrades --dry-run',
        'sudo journalctl -x',
        'rmmod --all --stacks ip_tables',
        'bcachefs format /dev/sd[ab] --foreground_target /dev/sda --promote_target /dev/sda --background_target /dev/sdb --metadata_target'
    ];

    const shineWindowLength = 25; 

    useEffect(() => {
        const shuffledArray = [...chars].sort(() => Math.random() - 0.5);
        setShuffledChars(shuffledArray);
    }, []);

    useEffect(() => {
        const writeNext = () => {
            if (index < shuffledChars.length) {
                const currentString = shuffledChars[index];
                if (charIndex < currentString.length) {
                    setBrainfuck(prev => prev + currentString.charAt(charIndex));
                    setCharIndex(prev => prev + 1);
                } else {
                    setBrainfuck(prev => prev + '   ');
                    setIndex(prev => prev + 1);
                    setCharIndex(0);
                }
            } else {
                setIndex(0);
                setCharIndex(0);
                //setShuffledChars(prev => [...prev].sort(() => Math.random() - 0.5));
            }

            if (brainfuck.length > 500) {
                setShining(true);
            }
        };

        const timeout = setTimeout(writeNext, 30 + Math.random() * 3);

        return () => clearTimeout(timeout);
    }, [index, charIndex, shuffledChars, brainfuck.length]);

    useEffect(() => {
        if (shining) {
            const shineEffect = () => {
                setShineIndex(prev => (prev + 2) % (brainfuck.length - shineWindowLength + 1)); 
            };

            const shineInterval = setInterval(shineEffect, 40); 

            return () => clearInterval(shineInterval);
        }
    }, [shining, brainfuck.length]);

    return (
        <div
            style={{
                position: 'absolute',
                boxSizing: 'border-box',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -1,
                overflow: 'hidden',
                whiteSpace: 'pre-wrap',
                fontFamily: 'monospace',
                fontSize: '15px',
                color: 'rgba(0, 0, 0, 0.1)', 
                padding: '20px',
                paddingTop: "70px",
                pointerEvents: 'none',
                WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0.2), transparent)',
                maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0.2), transparent)'
            }}
        >
            {brainfuck.split('').map((char, idx) => (
                <span
                    key={idx}
                    style={{
                        display: 'inline-block',
                        color: shining && idx >= shineIndex && idx < shineIndex + shineWindowLength ? 'white' : 'rgba(0, 0, 0, 0.1)',
                        transition: 'color 0.1s ease-in-out', 
                    }}
                >
                    {char}
                </span>
            ))}
        </div>
    );
};

export default TypingEffect;

import React from 'react';
import {
    Button,
    Box,
    Grid,
    TextField,
    Link,
    Typography,
    Alert
} from '@mui/material';

import { useAuth } from './useAuth';
import {useNavigate} from 'react-router-dom';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import {BACKEND_URL} from '../config'

import axios from 'axios';

function LoginPage(props){

    const navigate = useNavigate();
    const {isLoggedInRef, checkLogin, verifyLogin} = useAuth();
    const queryClient = useQueryClient();
    const {register, formState: {errors, dirtyFields}, handleSubmit, control} = useForm({
        mode: "onSubmit"
    });

    const sendLogin = async (data) => {
        const { data: response } = await axios.post(BACKEND_URL+"/auth/login", data, {withCredentials: true});
        return response.data;
    };

    function onSubmit(data){
        const message = {
            ...data
        };
        mutate(message);
    }


    const { mutate, isLoading, isError, error } = useMutation(sendLogin, {
        onSuccess: data => {
           window.location.reload();
     },
       onError: error => {
     },
       onSettled: () => {
          queryClient.invalidateQueries('login')
     }
     });

    useEffect(() => {
        checkLogin().then(()=>{
            if(isLoggedInRef.current){
                navigate("/");
            }
        })
    }, []);

    return(
        <Box sx={{pt:10}}>
            <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{pt: 2}}>
                <Typography component="h1" variant="h5">Sign in</Typography>
                <Grid container item columns={{xs: 4, sm: 8, md: 12}} alignItems="center" justifyContent="center">
                <Grid item xs={4}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate xs={2}>
                        <TextField
                            {...register('username', { required: "Username is required"})}
                            error={!!errors.username}
                            helperText={!!errors.username ? errors.username.message : null}
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            variant='filled'
                        />
                        <TextField
                             {...register('password', { required: "Password is required"})}
                            error={!!errors.password}
                            helperText={!!errors.password ? errors.password.message : null}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            variant='filled'
                        />
                        {isError && <Alert severity="error">{error?.response?.data?.userMessage}</Alert>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            >
                            Sign In
                        </Button>
                        
                        <Grid container>
                            <Grid item>
                                <Link 
                                    to="request_access" 
                                    variant="body2"
                                    sx={{cursor: "pointer"}}
                                    onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/request_access");
                                    }}>
                                {"Request access"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default LoginPage;
import React from 'react'

import {
    Box
} from '@mui/material'
import SingleSubtaskResultsCard from './SingleSubtaskResultsCard';

function SingleTaskResultsOverview(props){
    return(
        <Box>
            <h3>{props.task.task_name}</h3>
            {props.task.subtasks.map((subtask,i) => <SingleSubtaskResultsCard key={subtask.name} task={subtask} />)}
        </Box>
    )
};

export default SingleTaskResultsOverview;
import React from 'react';
import {
    Button,
    Box,
    Grid,
    TextField,
    Link,
    Typography,
    Alert,
    Paper
} from '@mui/material';

import { useAuth } from './useAuth';
import {useNavigate} from 'react-router-dom';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import {BACKEND_URL} from '../config'

import axios from 'axios';

function RequestAccessPage(props){

    const navigate = useNavigate();
    const {isLoggedInRef, checkLogin} = useAuth();
    const queryClient = useQueryClient();
    const {register, formState: {errors, dirtyFields}, handleSubmit, control} = useForm({
        mode: "onSubmit"
    });

    const sendAccessRequest = async (data) => {
        const { data: response } = await axios.post(BACKEND_URL+"/auth/invites", data, {withCredentials: true});
        return response;
    };

    function onSubmit(data){
        const message = {
            ...data
        };
        accessRequest.mutate(message);
    }


    const accessRequest = useMutation(sendAccessRequest, {
        onSuccess: data => {
            accessRequest.data = data;
     },
       onError: error => {
            console.log(error);
            accessRequest.error = error;
     },
       onSettled: () => {
     },
     });

    function login(){
        window.location.href = BACKEND_URL+"/auth/google";
    }

    useEffect(() => {
        checkLogin().then(()=>{
            if(isLoggedInRef.current){
                navigate("/");
            }
        })
    }, []);

    return(
        (accessRequest.isLoading) ? (
            <Box sx={{pt:10}}>
                <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{pt: 2}}>
                    <Grid container item columns={{xs: 4, sm: 8, md: 12}} alignItems="center" justifyContent="center">
                    <Grid item xs={4}>
                        <Typography variant="h6">Loading...</Typography>
                    </Grid>
                    </Grid>
                </Grid>
            </Box>
        ) :
        (accessRequest.isSuccess) ? (
            
            <Box sx={{pt:10}}>
                <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{pt: 2}}>
                    
                    
                        {(accessRequest.data.pre_approved) ? (
                            <React.Fragment>
                            <Typography component="h1" variant="h5">Request approved!</Typography>
                            <Typography variant="body1">Your email is pre-approved for access.</Typography>
                            <Typography variant="body1">A sign-up link has been sent to your email, {accessRequest.data.email}.</Typography>
                            <Typography variant="body1">Click on the link to create your account and get started!</Typography>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                            <Typography component="h1" variant="h5">Request submitted</Typography>
                            <Typography variant="body1">Your request has been submitted. You will receive an email when your request has been approved.</Typography>
                            </React.Fragment>
                        )}
                </Grid>
            </Box>
        ) :

        <Box sx={{pt:10}}>
            <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{pt: 2}}>
                <Typography component="h1" variant="h5">Request Access</Typography>
                <Grid container item columns={{xs: 4, sm: 8, md: 12}} alignItems="center" justifyContent="center">
                <Grid item xs={4}>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate xs={2}>
                        <TextField
                            {...register('email', { required: "Email is required", 
                            pattern: {value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i, message: "Must be a valid email address"}})}
                            error={!!errors.email}
                            helperText={!!errors.email ? errors.email.message : null}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            type="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            variant='filled'
                        />
                        {accessRequest.status === "error" && <Alert severity="error">{accessRequest.error.response.data.userMessage}</Alert>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            >
                            Request Access
                        </Button>
                        
                        <Grid container>
                            <Grid item>
                                <Link 
                                    to="signup" 
                                    variant="body2"
                                    sx={{cursor: "pointer"}}
                                    onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/login");
                                    }}>
                                {"Already have an account? Sign In"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default RequestAccessPage;
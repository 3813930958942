import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import LinearProgressWithLabel from '../components/LinearProgressBar';
import { CardActionArea, CardActions, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BACKEND_URL } from '../config';

export default function ChapterOverviewCard({ id, title, description, tasks_total, tasks_completed }) {
    const progress = (tasks_completed / tasks_total) * 100;
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/chapters/${id}`);
    };

    return (
        <Card key={id} id={id} sx={{}}>
            <CardActionArea onClick={handleClick}>
                <CardMedia
                    component="img"
                    height="140"
                    image={`${BACKEND_URL}/chapters/${id}/image`}
                    alt="Chapter image"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgressWithLabel value={progress} />
                    </Box>
                </CardActions>
            </CardActionArea>
        </Card>
    );
}

import React from "react";
import { useQuery } from "react-query";
import axios from 'axios';
import {BACKEND_URL} from '../config'

import ChapterOverviewCard from "./ChapterOverviewCard";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

// Custom hook to fetch all chapters
function useChapters() {
  return useQuery(
    "chapters",
    async () => {
      const { data } = await axios.get(
        `${BACKEND_URL}/chapters`, 
        { withCredentials: true }
      );
      return data;
    },
    {
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
    }
  );
}

export default function ChaptersPage() {
  const { status, data, error } = useChapters();

  return (
    <>
    <h1>Chapters</h1>
    <Container sx={{pt: 2}}>
      {status === "loading" ? (
        "Loading..."
      ) : status === "error" ? (
        <span>Error: {error.message}</span>
      ) : (
        data && (
          <Grid container spacing={3}>
            {data.map((chapter) => (
              <Grid item xs={12} sm={6} md={4} key={chapter.id}>
                <ChapterOverviewCard 
                  id={chapter.id}
                  title={chapter.name}
                  /*description={"TODO"}*/
                  tasks_total={chapter.tasks_total}
                  tasks_completed={chapter.tasks_completed}
                />
              </Grid>
            ))}
          </Grid>
        )
      )}
    </Container>
    </>
  );
}

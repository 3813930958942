import React from "react";

import {

  Container,

  Card,
} from "@mui/material";

export default function HtmlBlock({content}) {
  return (
      <Container sx={{ mt: 2, mb: 2 }}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Container>
  );
}

import { 
    Card,
    Box,
    Grid
} from '@mui/material';
import React, { useEffect } from 'react';

import {useAuth} from '../auth/useAuth';
import {useNavigate} from 'react-router-dom';
import {BACKEND_URL} from '../config';

function WelcomePage() {
    const {isLoggedIn} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    return (
        <Box sx={{ position: 'relative', height: '80vh', overflow: 'hidden' }}>
            <Box sx={{ position: 'relative', zIndex: 1 }}>
                <h1>Shellery.net</h1>
                <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center">
                    <Grid container item alignItems="center" columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="center">
                        <Grid item xs={3}>
                            <Card sx={{ p: 2 }}>
                                <img src="./img/shellery.png" style={{ width: "100%" }} alt="Shellery" />
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container item columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" justifyContent="center">
                        <Grid item xs={4}>
                        <h2 style={{textAlign: "center"}} >Ascend Linux Course</h2>
                            <Card sx={{ textAlign: "center", p: 1 }}>
                                <p>Welcome to the Ascend Linux course!</p>
                                <p>Use the top menu to navigate the page.</p>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default WelcomePage;

import React from "react";
import TaskPreviewCard from "./TaskPreviewCard";
import ChapterTasksCard from "./ChapterTasksCard";
import { useQuery } from "react-query";
import axios from 'axios';
import {BACKEND_URL} from '../config'
import {Alert} from '@mui/material';

function useTasks() {
  return useQuery("tasks", async () => {
    const { data } = await axios.get(
      BACKEND_URL + '/tasks', { withCredentials: true }
    );
    return data;
  }, {
    refetchInterval: 1000,
    refetchIntervalInBackground: true,
  });
}

function TasksPage(props) {
  const { status, data, error } = useTasks();

  return (
    <>
    <h1>Tasks</h1>
    <Alert sx={{mb:2}} severity="info">All tasks from all chapters are displayed here, but not necessarily in a logical order. We recommend working through the tasks from the "Chapters" view instead.</Alert>
      {status === "loading" ? (
        "Loading..."
      ) : status === "error" ? (
        <span>Error: {error.message}</span>
      ) : (
        data ? (
          <>
                {data?.groups?.map((group,i) => <ChapterTasksCard 
                    key={group.id}
                    id={group.id}
                    name={group.name}
                    description={group.description}
                    tasks={group.tasks}
                    completed={group.completed}
                />)}   
              </>
        ) : null
      )}
    </>
  );
}

export default TasksPage;
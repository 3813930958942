import React from 'react';

import mockData from '../mock_tasks.json';

import SingleTaskResultsOverview from './SingleTaskResultsOverview';
import LeaderboardPodium from './LeaderboardPodium';
import { useQuery } from "react-query";
import axios from 'axios';
import {BACKEND_URL} from '../config'
import SingleSubtaskResultsCard from './SingleSubtaskResultsCard';

function useLeaderboard() {
    return useQuery("leaderboard", async () => {
      const { data } = await axios.get(
        BACKEND_URL + '/leaderboard', {withCredentials: true}
      );
      return data;
    },
    {
      refetchInterval: 5000,
      refetchIntervalInBackground: true,
    });
  }

function LeaderboardPage(){
    const { status, data, error, isFetching } = useLeaderboard();
    
    return(

        <React.Fragment>
        <h1>Leaderboard</h1>
        <div>
            {status === "loading" ? (
              "Loading..."
            ) : status === "error" ? (
              <span>Error: {error.message}</span>
            ) : (
              <>
                <h2>Podium</h2>
                <LeaderboardPodium rankings={data?.rankings}/>
                  <h2>Task overview</h2>
                  <p>All tasks arranged by the number of users who have completed them</p>
                    {data?.tasks?.map((item,i) => <SingleSubtaskResultsCard key={item.task_id} task={item} />)}   
              </>
            )}
          </div>
        
        </React.Fragment>
    );
}

export default LeaderboardPage;

import React from 'react'

import {
    Card,
    CardHeader,
    Chip,
    Typography,
    Avatar,
    Box,
    Container,
    Paper
} from '@mui/material'

function LeaderboardPodium({rankings}){

    function rankingColor(rank){
        if (rank == 1){
            return "gold"
        } else if (rank ==2){
            return "silver"
        } else if (rank == 3){
            return "#CD7F32"
        } else {
            return "white"
        }
    }
    
    return(
        <>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}} >
        {rankings?.map((item,i) => 
            
            <Card key={[item.ranking,item.username]} sx={{maxWidth: "400px", p:2, mb: 1, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left"}}>
                    <Avatar sx={{
                        boxShadow: 1, 
                        backgroundColor: rankingColor(item.ranking), 
                        color: "black", mr: 2}}>
                            {item.ranking}
                    </Avatar>
                    <Typography>{item.username}</Typography>
                </Box>
                <Typography>:   {item.score}</Typography>
            </Card>
        )}
    </Box>
    </>
    );
};

export default LeaderboardPodium;
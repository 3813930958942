import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import axios from 'axios';
import {BACKEND_URL} from '../config'

import TaskBlock from "./TaskBlock";
import HtmlBlock from "./HtmlBlock";

// Custom hook to fetch chapter data
function useChapter(id) {
  return useQuery(
    ["chapter", id], 
    async () => {
      const { data } = await axios.get(
        `${BACKEND_URL}/chapters/${id}`, 
        { withCredentials: true }
      );
      return data;
    },
    {
      refetchInterval: 10000,
      refetchIntervalInBackground: true,
    }
  );
}

function createBlock(block){
  if (block.type == "task"){
   return  <TaskBlock key={block.task.id} task={block.task} />
  } else if (block.type == "html"){
    return <HtmlBlock content={block.html} />
  }
}

export default function SingleChapterPage() {
  const { chapterId } = useParams();
  const { status, data, error } = useChapter(chapterId);

  return (
    <div>
      {status === "loading" ? (
        "Loading..."
      ) : status === "error" ? (
        <span>Error: {error.message}</span>
      ) : (
        data && (
          <>
            {data.page?.blocks?.map((block, i) => (createBlock(block)))}
          </>
        )
      )}
    </div>
  );
}

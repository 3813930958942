import React from "react";
import { useQuery } from "react-query";
import axios from "axios";
import {BACKEND_URL} from '../config'

import {
  Alert,
  TextField,
  Container,
  Button,
  Tooltip,
  Chip,
  Box,
  Typography,
  Card,
} from "@mui/material";

import { green, grey } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";;

function useSubmitFlag(id, val) {
  return useQuery(
    "task" + id,
    async () => {
      const { data } = await axios.post(
        `${BACKEND_URL}/tasks/${id}/submit_flag`,
        { flag: val },
        { withCredentials: true }
      );
      return data;
    },
    {
      manual: true,
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export default function TaskBlock(props) {
  const [answer, setAnswer] = React.useState("");
  const submitFlagResult = useSubmitFlag(props.task.id, answer);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitFlagResult.refetch();
    }
  };

  return (
    <Card sx={{ mt: 2, p: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
        {props.task.completed ? (
          <Tooltip title="Task completed">
            <CheckCircleIcon sx={{ color: green[400], mr: 2 }} />
          </Tooltip>
        ) : (
          <Tooltip title="Task not completed">
            <CheckCircleIcon sx={{ color: grey[300], mr: 2 }} />
          </Tooltip>
        )}
        <Typography variant="h6">{props.task.name}</Typography>
        {(() => {
          switch (props.task.type) {
            case "auto":
              return (
                <Tooltip title="Task is automatically verified as completed">
                  <Chip
                    sx={{ marginLeft: 2 }}
                    label="Auto"
                    size="small"
                    variant="outlined"
                  />
                </Tooltip>
              );
            case "flag":
              return (
                <Tooltip title="You need to submit an answer below">
                  <Chip
                    sx={{ marginLeft: 2 }}
                    label="Flag"
                    size="small"
                    variant="outlined"
                  />
                </Tooltip>
              );
            default:
              return null;
          }
        })()}
      </Box>

      <Container sx={{ mt: 2, mb: 4 }}>
        <div dangerouslySetInnerHTML={{ __html: props.task.description }} />
      </Container>
      {props.task.title}

      {!props.task.completed && props.task.type === "flag" && (
        <div>
          {submitFlagResult.status === "loading" ? (
            "Verifying..."
          ) : submitFlagResult.status === "error" ? (
            <Alert severity="error">
              Error: {submitFlagResult.error.response.data?.userMessage}
            </Alert>
          ) : submitFlagResult.status === "idle" ? null : (
            <Alert severity="success">Correct!</Alert>
          )}
          <Box
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "row",
              borderRadius: 2,
              mt: 3,
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <TextField
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              label="Write your answer here"
              variant="filled"
              onKeyDown={handleKeyDown}
            />
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={submitFlagResult.refetch}
            >
              Submit
            </Button>
          </Box>
        </div>
      )}
    </Card>
  );
}

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';

import { useAuth } from '../auth/useAuth';

import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import UserMenuItem from './UserMenuItem';


const LinuxCourseAppBar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const {verifyLogin, logout, isLoggedIn, isLoginPending} = useAuth();
  const navigate = useNavigate();

  const handleHomeNavMenu = () => {
    handleCloseNavMenu();
    navigate("/");
  }
  const handleComputersNavMenu = () => {
    handleCloseNavMenu();
    navigate("/computers");
  };
  const handleTasksNavMenu = () => {
    handleCloseNavMenu();
    navigate("/tasks");
  };
  const handleChaptersNavMenu = () => {
    handleCloseNavMenu();
    navigate("/chapters");
  };
  const handleLeaderboardNavMenu = () => {
    handleCloseNavMenu();
    navigate("/leaderboard");
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };



  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, cursor: "pointer"}}
            onClick={handleHomeNavMenu}
          >
          Shellery.net
          </Typography>
          {(isLoggedIn) &&
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem key="computers" onClick={handleComputersNavMenu}>
                  <Typography textAlign="center">Computers</Typography>
                </MenuItem>
                <MenuItem key="chapters" onClick={handleChaptersNavMenu}>
                  <Typography textAlign="center">Chapters</Typography>
                </MenuItem>
                <MenuItem key="tasks" onClick={handleTasksNavMenu}>
                  <Typography textAlign="center">Tasks</Typography>
                </MenuItem>
                <MenuItem key="leaderboard" onClick={handleLeaderboardNavMenu}>
                  <Typography textAlign="center">Leaderboard</Typography>
                </MenuItem>
              </Menu>
            </Box>
          }
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, cursor: "pointer"}}
            onClick={handleHomeNavMenu}
          >
          Shellery.net
          </Typography>
          {(isLoggedIn) &&
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Button
                  key="computers"
                  onClick={handleComputersNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Computers
                </Button>
                <Button
                  key="chapters"
                  onClick={handleChaptersNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Chapters
                </Button>
                <Button
                  key="tasks"
                  onClick={handleTasksNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Tasks
                </Button>
                <Button
                  key="leaderboard"
                  onClick={handleLeaderboardNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  Leaderboard
                </Button>
            </Box>
          }
          <UserMenuItem />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default LinuxCourseAppBar;
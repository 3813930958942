import React from "react"; 
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { useAuth } from "../auth/useAuth";


const UserMenuItem = (props) =>
{
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const navigate = useNavigate();

    const {logout, isLoggedInRef, avatarUrlRef} = useAuth();

    const handleLoginUserMenu = () => {
        handleCloseUserMenu();
        navigate("/login");
      };
      const handleCloseUserMenu = () => {
        setAnchorElUser(null);
      };
      const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
      };
    
    if (isLoggedInRef.current){
        return (
            <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Avatar"  />
            </IconButton>
            </Tooltip>
            <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            >
            <MenuItem key='logout' onClick={logout}>
                <Typography textAlign="center">Log out</Typography>
            </MenuItem>
            </Menu>
        </Box>);
    }
    else {
        return( 
            <Box>
                <Button
                    key="login"
                    onClick={handleLoginUserMenu}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                >
                    Login
              </Button>
            </Box>
        )

    }
}

export default UserMenuItem;
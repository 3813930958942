import React from 'react';
import {
    Button,
    Box,
    Grid,
    TextField,
    Link,
    Typography,
    Alert
} from '@mui/material';

import { useAuth } from './useAuth';
import {useNavigate, useLocation, useParam} from 'react-router-dom';
import { useEffect } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import {BACKEND_URL} from '../config'

import axios from 'axios';

function validatePassword(value) {
    let count = 0;
  
    if (/[a-z]/.test(value)) count++;
    if (/[A-Z]/.test(value)) count++;
    if (/[0-9]/.test(value)) count++;
    if (/[^a-zA-Z0-9]/.test(value)) count++;
  
    if (count < 2 || value.length < 8) {
      return "Password must be at least 8 characters long and contain at least 2 of the 4 character types: lowercase letters, uppercase letters, numbers, and special characters.";
    }
  
    return true;
  }

function SignupPage(props){
    const navigate = useNavigate();
    const {isLoggedInRef, checkLogin, verifyLogin} = useAuth();
    const queryClient = useQueryClient();
    const {register, formState: {errors, dirtyFields}, handleSubmit, control} = useForm({
        mode: "onSubmit"
    });

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const invite = searchParams.get("invite");


    function useInviteData() {
        return useQuery("tasks", async () => {
          const { data } = await axios.get(
            BACKEND_URL + `/auth/invites/${invite}`, {withCredentials: true}
          );
          return data;
        },{
            enabled: !!invite
        });
    }

    const inviteReq = useInviteData();

    const sendSignup = async (data) => {
        const { data: response } = await axios.post(BACKEND_URL+`/auth/invites/${invite}/signup`, data, {withCredentials: true});
        return response.data;
    };

    function onSubmit(data){
        const message = {
            ...data
        };
        mutate(message);
    }


    const { mutate, isLoading, isError, error, data } = useMutation(sendSignup, {
        onSuccess: data => {
           verifyLogin().then(()=>{
                navigate("/");
           })
     },
       onError: error => {
     },
       onSettled: () => {
          queryClient.invalidateQueries('login')
     }
     });


    useEffect(() => {
        checkLogin().then(()=>{
            if(isLoggedInRef.current){
                navigate("/");
            }
        })
    }, []);

    if (!invite){
        return (<Alert severity="error">Missing Invite ID</Alert>)
    }

    return(
        inviteReq.status === "loading" ? (
            "Loading..."
        ) : inviteReq.status === "error" ? (
            <span>Error: {}</span>
        ) : (
        <Box sx={{pt:10}}>
            <Grid container direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{pt: 2}}>
                <Typography component="h1" variant="h5">Sign up</Typography>
                <Grid container item columns={{xs: 4, sm: 8, md: 12}} alignItems="center" justifyContent="center">
                <Grid item xs={4}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate xs={2}>
                    <TextField
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Email"
                            helperText="This is the email you used to request access"
                            name="name"
                            value={inviteReq?.data?.email}
                            variant='filled'
                            disabled
                        />
                        <TextField
                            {...register('username', { required: "Username is required", 
                            pattern: {value: /^[a-z_]([a-z0-9_-]{1,31})$/i, message: "Username must be between 2 and 32 characters and can only contain lowercase letters, numbers, underscores, and dashes."}})}
                            error={!!errors.username}
                            helperText={!!errors.username ? errors.username.message : null}
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            variant='filled'
                        />
                        <TextField
                             {...register('password', { 
                                required: "Password is required", 
                                validate: {
                                    customValidation: validatePassword
                                }
                            })}
                            error={!!errors.password}
                            helperText={!!errors.password ? errors.password.message : null}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            variant='filled'
                        />
                        {isError && <Alert severity="error">{error?.response?.data?.userMessage}</Alert>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            >
                            Sign Up
                        </Button>
                        
                        <Grid container>
                            <Grid item>
                                <Link 
                                    to="signup" 
                                    variant="body2"
                                    sx={{cursor: "pointer"}}
                                    onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/login");
                                    }}>
                                {"Already have an account? Sign In"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
    )
}

export default SignupPage;
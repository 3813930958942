import logo from './tux.svg';
import './App.css';
import LinuxCourseAppBar from './components/LinuxCourseAppBar';
import TasksPage from './tasks/TasksPage';
import ComputersPage from './computers/ComputersPage';
import LeaderboardPage from './leaderboard/LeaderboardPage';
import WelcomePage from './welcome/WelcomePage';
import { AuthProvider, useAuth } from "./auth/useAuth.jsx";
import LoginPage from './auth/LoginPage';
import SignupPage from './auth/SignupPage';
import ChaptersPage from './chapters/ChaptersPage.jsx'
import SingleChapterPage from './chapters/SingleChapterPage.jsx';
import {
  Container
} from '@mui/material'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import RequestAccessPage from './auth/RequestAccessPage';
import TypingEffect from './components/TypingEffect';


function App() {
  const queryClient = new QueryClient();

  return (
    <>
    <div className="App">
      <Router>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <LinuxCourseAppBar />
            <Container id="outer-container" sx={{pb:4}}>
              <div></div>
              <Routes>
                  <Route path="/" element={<WelcomePage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/computers" element={<ComputersPage />} />
                  <Route path="/leaderboard" element={<LeaderboardPage/>} />
                  <Route path="/tasks" element={<TasksPage/>} />
                  <Route path="/chapters" element={<ChaptersPage/>} />
                  <Route path="/chapters/:chapterId" element={<SingleChapterPage />}/>
                  <Route path="/request_access" element={<RequestAccessPage />} />
                  <Route path="/signup" element={<SignupPage />} />
              </Routes>  
            </Container>
          </AuthProvider>
        </QueryClientProvider>
      </Router>
    </div>
    </>
  );
  
}

export default App;

import React from 'react';
import {useContext} from "react";

import {
    useNavigate,
    useLocation,
} from "react-router-dom";
import axios from "axios";
import {BACKEND_URL} from '../config'

import useState from 'react-usestateref'

const authContext = React.createContext(null);

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoggedIn, setIsLoggedIn, isLoggedInRef] = useState(sessionStorage.getItem('logged_in')?? false);
    const [isLoginPending, setIsLoginPending, isLoginPendingRef] = useState(false);
    const [avatarUrl, setAvatarUrl, avatarUrlRef] = useState(sessionStorage.getItem('avatar_url')?? false);

    const verifyLogin = () => {
        return new Promise((res) => {
            setIsLoginPending(true);
            axios.get(BACKEND_URL+"/auth/whoami", {withCredentials: true, crossDomain: true})
            .then(axios_res => {
                // Store in session storage
                sessionStorage.setItem('logged_in', "true");
                console.log(axios_res);
                setAvatarUrl("https://picsum.photos/536/354");
                sessionStorage.setItem('avatar_url', "https://picsum.photos/536/354");
                setIsLoggedIn(true);
                setIsLoginPending(false);
                res();
            })
            .catch((error) => {
                // User should be null when not successfully authenticated
                setIsLoggedIn(false);
                setIsLoginPending(false);
                setAvatarUrl(false);
                sessionStorage.clear();
                // Not logged in, take user to login page
                navigate("/login", {replace: true, state: {from: location}})
                res();
            })
        });
    }
    const checkLogin = () => {
        return new Promise((res) => {
            setIsLoginPending(true);
            axios.get(BACKEND_URL+"/auth/whoami", {withCredentials: true, crossDomain: true})
            .then(axios_res => {
                // Store in session storage
                sessionStorage.setItem('logged_in', "true");
                console.log(axios_res);
                setAvatarUrl("https://picsum.photos/536/354");
                sessionStorage.setItem('avatar_url', "https://picsum.photos/536/354");
                setIsLoggedIn(true);
                setIsLoginPending(false);
                res();
            })
            .catch((error) => {
                // User should be null when not successfully authenticated
                setIsLoggedIn(false);
                setIsLoginPending(false);
                setAvatarUrl(false);
                sessionStorage.clear();
                res();
            })
        });
    }

    const logout = () => {
        axios({
            method : "POST",
            withCredentials: true,
            url : BACKEND_URL + "/auth/logout"
            }
        ).then(() => {
            setIsLoggedIn(false);
            setIsLoginPending(false);
            sessionStorage.clear();
            navigate("/login");
        }).catch(err => {
            console.error(err);
        })
    };

    return(
        <authContext.Provider value={{verifyLogin, checkLogin, logout, avatarUrl, isLoggedIn, isLoginPending, isLoggedInRef, isLoginPendingRef, avatarUrlRef}}>
            {children}
        </authContext.Provider>
    )
}

export const useAuth = () => useContext(authContext);